import React from 'react'
import {graphql, Link, navigate} from 'gatsby'
import SEO from '../components/SEO'

import Questionaire from '../components/Questionaire'
import Layout from '../components/Layout'

import RetryButton from '../components/RetryButton'



import {
	Breadcrumb,
	Button,
	Container,
	Icon,
	Message,
	Item,
	Image
} from 'semantic-ui-react'
import * as styles from './styles/template.module.css'
import * as franchisestyles from './styles/franchisecontentpage.module.css'

import {
	mobileMaxWidth,
	resizeTimer,
	tabletMaxWidth,
	confirmationColor
} from '../globalVar'

import headingimg from "../images/franchise/welcomeinterestedfranchisee.png"


const Negosyo = require('../../lib/negosyo')



class FranchiseApplyPageTemplate extends React.PureComponent {
	state = {
		pageid: 0,
		record: this.props.data.negosyoNow.viewFranchise,
		message: "",
		pageLoading:true,
		error:false,
		submitted: false,
		pageSection:[],
		allowFinalize: false,
		username: ""
	}

	componentDidMount() {
		this.fetchData();
	}

	returnPage = () => {
		if (this.state.submitted) {
			navigate('/franchise/resource/'+this.props.pageContext.url);
		} else {
			navigate('/franchise/'+this.props.pageContext.url);
		}
	}

	fetchData = (retry=false) => {
		const {
			url
		} = this.props.pageContext
		Negosyo.isLoggedIn()
		.then((isLoggedIn)=>{
			if(isLoggedIn) {
				Negosyo.getCurrentUserDetails()
				.then((user)=>{
					this.loadFranchiseDetails(url, user.username);
				}).catch((err)=>{
					this.loadFranchiseDetails(url, "");
				})
			} else {
				this.loadFranchiseDetails(url, "");
			}
		}).catch((err)=>{
			this.loadFranchiseDetails(url, "");
		})
	}

	loadFranchiseDetails = (url, username) => {
		this.setState({
			pageLoading:true,
			message:"Loading..."
		}, function(){
			Negosyo.getFranchiseDetails(url)
			.then((data)=>{
				this.setState({
					record:data,
				}, function() {
					Negosyo.getFranchiseQuestions(url)
					.then((data)=>{
						this.setState({
							pageSection:data,
							message:""
						}, function() {
							this.handleSignIn(username);
							this.setState({
								pageLoading:false
							});
						})
					}).catch((err)=>{
						this.setState({
							error:true,
							pageLoading:false,
							message:""
						})
					})
				})
			}).catch((err)=>{
				this.setState({
					error:true,
					pageLoading:false,
					message:""
				})
			})
		});
	}

	handleSignIn = (username) => {
		// Check if already registered
		if (username.length > 0) {
			if (username === this.state.username) {
				// Method may get called multiple times, check if same username
				return;
			}
			this.setState({
				username: username,
				message: "Please wait..."
			}, function() {
				(async () => {
					const response = await Negosyo.checkFranchiseRegistered(username, this.props.pageContext.url);
					if (response.hasOwnProperty("registered") && response.hasOwnProperty("message")  && response.hasOwnProperty("allowed")) {
						this.setState({
							submitted: response.registered,
							allowFinalize: response.allowed,
							//message: response.message
							message: response.allowed===false?"Application Status:"+response.message:""
						});
					} else {
						this.setState({
							submitted: false,
							allowFinalize: true,
							message: ""
						});
					}
				})();
			});
		} else {
			this.setState({
				username: username,
				allowFinalize: true
			});
		}
	}


	handleSubmit = (submitData) => {
		const finalsubmitData = {
			franchiseid: this.state.record.url,
			username: submitData.username,
			fields: [
				{"label":"Franchise Name", "value": this.state.record.name},
			].concat(submitData.fields)
		};
		this.setState({
			message:"Processing...",
			pageLoading:true
		}, function() {
			Negosyo.applyFranchise(finalsubmitData)
			.then((result)=>{
				if(result.status==="OK") {
					window.dataLayer = window.dataLayer || [];
					window.dataLayer.push({
							'event': 'franchise_application',
							'name': this.state.record.name,
						});

					this.setState({
						message:"Successfully Submitted Application.  Please wait for our representative to get in touch.",
						pageLoading:false,
						submitted: true
					})
					/*
					const timeOut = setTimeout(()=>{
						this.setState({
							message:""
						})
					}, 5000)
					this.setState({
						timeOutRef:timeOut
					})
					*/
				}else {
					this.setState({
						message:"Unable to send request",
						pageLoading:false
					})
				}


			}).catch((err)=>{
				this.setState({
					message:"Unable to send request",
					pageLoading:false
				})
			})
		})
	}

	render() {
		const {
			record,
			error,
			pageLoading,
			pageSection
		} = this.state
		const {
			location
		} = this.props
		const {
			fetchData
		} = this

		return (

			<Layout location={location} backButton={true}>
				<SEO
					canonicalpath={"/franchise/apply/"+record.url}
					title={record===undefined ? "Franchise Application Form":record.name}
					meta={[{name: `robots`, content: `noindex`}]}
				/>
				<Container text className={styles.container}>
					<Breadcrumb className={styles.desktopOnly}>
						<Breadcrumb.Section content="Home" as={Link} to="/"/>
						<Breadcrumb.Divider />
						<Breadcrumb.Section content={"Franchise"} as={Link} to={"/franchise/"} />
						<Breadcrumb.Divider />
						<Breadcrumb.Section content={"Apply for "+record.name+" franchise"} />
					</Breadcrumb>
					<Breadcrumb className={styles.mobileOnly}>
						<Breadcrumb.Section as={Link} to={"/franchise/"} >
							<Icon name="arrow left" />
							{"Franchise"}
						</Breadcrumb.Section>
					</Breadcrumb>
					<br/>
					<Item.Image size="massive">
						<Image src={record.contentImage} alt={record.name} />
					</Item.Image>
					<div className={franchisestyles.bannerheading}>
						<h1><img className={franchisestyles.bannerapplyheadingimage} src={headingimg} alt="Welcome Interested Franchisee" /></h1>
						<div>
							<div className={franchisestyles.bannerapplyheadingtext}>
								Fill up the following details to show your intent in applying for a {record.name} Franchise!
							</div>
						</div>
					</div>
					<span id="page-top" className={styles.anchor}/>
					{error===true  && (
						<RetryButton
							retryFunc={()=>{
								this.setState({
									error:false,
									pageLoading:true
								})
								fetchData(true)
							}}
							errorMessage={"Unable to load Franchise Application Form"}
						/>
					)}
					{(this.state.allowFinalize && this.state.submitted === false) ?
						<>
							<Questionaire
								questionlist={pageSection}
								requirelogin={true}
								pageLoading={pageLoading}
								customSubmit={this.handleSubmit}
								customSignIn={this.handleSignIn}
								customReturn={this.returnPage}
								agreechecklist={[
									"THIS IS TO EXPRESS MY INTEREST to Franchise "+record.name+". I would like to know more about this business opportunity and I am willing to receive additional communications, such as calls, SMS and emails, regarding this and similar products and services.",
									"I HEREBY CERTIFY that the information provided is complete, true and correct to the best of my knowledge.",
									"I GIVE MY EXPLICIT CONSENT for the website to store and process the information I have provided.",
									"I ACKNOWLEDGE AND ALLOW the website to share these details with the Franchisor, as well as any necessary third-party entities involved in the application process"
								]}
							/>
							{this.state.message.length>0 && <div>{this.state.message}</div>}
						</>
					:
						<div className={styles.centerText}>
							{this.state.message.length>0 &&
								<Message warning className={styles.msg}>
									{this.state.message}
								</Message>
							}
							<Button
								color={confirmationColor}
								loading={pageLoading}
								disabled={pageLoading}
								onClick={this.returnPage}
								className={styles.form}
							>
								OK
							</Button>

						</div>
					}
				</Container>
			</Layout>
		)
	}
}

export default FranchiseApplyPageTemplate



export const data = graphql`
	query FranchiseApplyQuery($url:String!) {
		negosyoNow {
			viewFranchise( url: $url ) {
				id
				name
				summary
				url
				mainImageHref
				slug
				organizationName
				organizationUrl
				sku
				available
				sections {
					content
					externalLink
					imgurl
					internalLink
					items {
						type
						title
						internalLink
						imgurl
						externalLink
						content
					}
					type
					title
				}
			}
		}
	}
`


